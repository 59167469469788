AOS.init({
    duration: 1000,
    easing: 'ease-in-sine',
    delay: 70,

});

$(function(){
    $('#logo_carousel').slick({
        slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3850,
		dots: false,
		arrows: true,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 1,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });

  $(function(){
    $('#logo_carousel1').slick({
        slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2050,
		dots: false,
		arrows: false,
		infinite: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		swipeToSlide: false,
		touchMove: false,
		responsive: [{
				breakpoint: 1570,
				settings: {
					slidesToShow: 4,
                    slidesToScroll: 1,
				}
			},
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
    });
  });

  $(document).ready(function() {
	$('#logo_carousel1').each(function () {

		$(this).magnificPopup({
			delegate: 'a', // the selector for gallery item
			type: 'image',
			gallery: {
			  enabled:true
			}
		});
	})
});